@use "~bootstrap/scss/bootstrap.scss";

.app {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  position: relative;
  display: flex;
  max-width: 1440px;
  margin: 0 auto;
}

.left {
  width: 80%;
  height: 100%;
  padding: 0 20px;
  border-left: 1px solid rgb(97, 105, 111);
  border-right: 1px solid rgb(97, 105, 111);
}
.right {
  width: 20%;
  height: 100%;
  color: white;
  border-right: 1px solid rgb(97, 105, 111);
}
.map-wrapper {
  position: relative;
  width: 100%;
}
#map {
  width: 100%;
  height: 100%;
}
.map-sidebar {
  position: absolute;
  top: 5px;
  right: 12px;
  z-index: 1000;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: white;
  }
  input {
    border-right: 1px solid grey;
    border-left: 0px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
  }
  input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-right: 1px solid var(--color-primary) !important;
    border-left: 0px;
    border-top: 1px solid var(--color-primary) !important;
    border-bottom: 1px solid var(--color-primary) !important;
  }
}

.totals {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  padding: 15px 0;
  height: 130px;
  color: white;
  &_box {
    width: 220px;
    height: 60px;
    overflow-x: auto;
    font-size: 12px;
    font-weight: 700;
    padding: 2px 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
    }
    &_icon {
      width: 22px;
      margin-right: 5px;
    }
  }
}
.totals-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.costs {
  font-size: 13px;
}

#coordinateCsv {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.right-header {
  height: 10%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
}

.right-content {
  height: 84%;
  border-top: 1px solid rgb(97, 105, 111);
}

.right-title {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  margin: 0;
}
.right-list {
  height: 95%;
  overflow-y: auto;
}
.right-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.right-list-item {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 20px;
  font-size: 13px;
}

.info {
  width: 100%;
  background-color: var(--color-primary);
  padding-top: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
}
.info-container {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  th {
    color: white !important;
    font-size: 11px !important;
    padding: 2px 2px;
  }
  td {
    color: white !important;
    font-size: 11px !important;
    padding: 2px 2px;
  }
}

.info-header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 5px;
  margin-bottom: 10px;
  color: white;
}
.info-header-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  font-size: 11.5px;
  width: 100%;
  border: 1px solid rgb(97, 105, 111);
  padding: 3px 5px;
}
.info-header-item-value {
  font-weight: bold;
}

.info-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 7px;
  color: white;
  text-transform: uppercase;
  text-align: center;
}
.info-item {
  color: white;
  border: 1px solid gray;
  padding: 10px;
  margin-right: 10px;
  overflow: hidden;
  overflow-y: auto;
  height: 55%;
  width: 100%;
  font-size: 12px;
}

.info-item-row {
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-item-row-right {
  color: white;
}

.info-item-row-left {
  color: white;
  font-weight: bold;
}

.marker-change-area-button {
  @extend .btn;
  @extend .btn-primary;
  @extend .btn-sm;
  font-size: 11px;
}
.marker-pool-button {
  @extend .btn;
  @extend .btn-secondary;
  @extend .btn-sm;
  font-size: 11px;
  margin-left: 5px;
}

.costchange-negative {
  @extend .text-success;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}
.costchange-positive {
  @extend .text-danger;
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
}

.small-modal {
  width: 500px;
}

.big-modal {
  width: 1200px !important;
  max-width: none;
}

.map-pool {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--color-primary);
  color: white;
  border-radius: 4px;
  padding: 10px;
}
.map-pool-title {
  width: 100%;
  height: 7%;
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
  text-align: center;
}
.map-pool-list {
  width: 100%;
  height: 86%;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
}
.map-pool-customer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  color: white;
  margin-bottom: 8px;
  &:hover {
    cursor: pointer;
  }
}
.map-pool-footer {
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.info-table-header-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 73x;
}

.info-table-first-row {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
}

.slide-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  color: white;
  margin-right: -1px;
}

.hover-plus {
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #198754 !important;
  }
}

.hover-minus {
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #dc3545 !important;
  }
}

.exit-button {
  height: 32px;
}
